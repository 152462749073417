import React from "react";

const EyesOpen = () => {
  return (
    <svg
      width="22"
      height="25"
      viewBox="0 0 22 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.75 13.6083C6.05 5.54168 15.95 5.54168 19.25 13.6083"
        stroke="white"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 17.6417C10.6389 17.6417 10.2813 17.5634 9.94762 17.4114C9.61398 17.2594 9.31082 17.0366 9.05546 16.7557C8.80009 16.4748 8.59753 16.1413 8.45933 15.7743C8.32113 15.4073 8.25 15.0139 8.25 14.6167C8.25 14.2194 8.32113 13.8261 8.45933 13.4591C8.59753 13.092 8.80009 12.7586 9.05546 12.4777C9.31082 12.1968 9.61398 11.974 9.94762 11.8219C10.2813 11.6699 10.6389 11.5917 11 11.5917C11.7293 11.5917 12.4288 11.9104 12.9445 12.4777C13.4603 13.045 13.75 13.8144 13.75 14.6167C13.75 15.419 13.4603 16.1884 12.9445 16.7557C12.4288 17.323 11.7293 17.6417 11 17.6417Z"
        stroke="white"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EyesOpen;
