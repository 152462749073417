import React, { useRef, useState, useEffect } from "react";
import Footer from "../../components/footer";
import Nav from "../../components/nav";
import Input from "../../components/input";
import { FreeBtn } from "../../components/button";
import { VideoSelector } from "../../components/input";
import toast, { LoaderIcon } from "react-hot-toast";
import { useSubmitEntry } from "../../hooks/competition.hook";
import { useNavigate } from "react-router-dom";

const Competition = () => {
  const navigate = useNavigate();
  const [screen, setScreen] = useState("form");
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    referral: "",
  });
  const [fileName, setFileName] = useState("");
  const { mutate, isSubmitting } = useSubmitEntry();

  const saveFormData = (e) => {
    e.preventDefault()
    localStorage.setItem('formData', JSON.stringify(formData))
    setScreen("payment");
  }

  useEffect(() => {
    const form = localStorage.getItem('formData')
    if (form) {
      setFormData(JSON.parse(form))
    }
  }, []);

  const formDataChangeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const back = () => {
    setScreen("form");
  }

  const proceed = () => {
    setScreen("upload")
  }

  const videoRef = useRef(null);
  const handleVideoInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileName(file.name);
    }
  };

  const upload = () => {
    const data = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      data.append(key, value);
    });
    data.append("video", videoRef.current.files[0]);
    mutate(data, {
      onSuccess: () => {
        setScreen("form")
        localStorage.removeItem("formData")
        setFormData({
          first_name: "",
          last_name: "",
          email: "",
          referral: "",
        })
      },
    });
  };
  const videoUploader = (e) => {
    e.preventDefault();
    const videoFile = videoRef.current.files[0];
    const maxFileSizeMB = 220;
    const maxDurationSecs = 120;
    if (!videoFile) {
      toast.error("Select Video to upload");
      return;
    }
    if (videoFile.size / (1024 * 1024) > maxFileSizeMB) {
      toast.error("Video can not be larger than 220mb");
      return;
    }
    const videoElement = document.createElement("video");
    videoElement.src = URL.createObjectURL(videoFile);
    const reader = new FileReader();
    reader.onload = function (e) {
      var timer = setInterval(function () {
        if (videoElement.readyState === 4) {
          if (videoElement.duration.toFixed(2) > maxDurationSecs) {
            toast.error("Video cannot be longer than 2 minutes");
          } else {
            upload();
          }
          clearInterval(timer);
        }
      }, 500);
    };
    reader.readAsDataURL(videoFile);
  };
  return (
    <div className="bg-bg min-h-screen flex flex-col justify-between">
      <div className="px-7 md:px-14 pt-9">
        <Nav />
        <div className="flex flex-col items-center mt-5 md:mt-10">
          {screen === "upload" ? (
            <>
              <h3 className="text-white uppercase text-2xl font-extrabold">
                Upload Video
              </h3>
              <form
                className="text-white w-full md:w-[500px] md:p-4 flex flex-col mt-5 gap-5"
                onSubmit={videoUploader}
              >
                <VideoSelector
                  name={"video"}
                  label={"Upload Video"}
                  onChange={handleVideoInputChange}
                  fileName={fileName}
                  ref={videoRef}
                />
                <FreeBtn
                  classes="text-black"
                  type="submit"
                  diabled={isSubmitting}
                >
                  {isSubmitting ? <LoaderIcon /> : "Upload"}
                </FreeBtn>
              </form>
            </>
          ) : screen === "payment"? (
            <>
              <div className="flex flex-col gap-3.5 items-center">
                <h3 className="text-white uppercase text-2xl font-extrabold">
                  Upload Video
                </h3>
                <p className="text-white font-semibold text-center">
                  Make payment to complete your entry
                </p>
              </div>
              <div className="text-white w-auto md:w-[500px] md:p-4 flex flex-col mt-5 gap-5">
                <p>You're are to make a payment of one thousand naira (₦1,000) to the account below, ensure the name in the previous form matches the account name otherwise your entry won't be acknowledged</p>
                <p>Account Name: <span className="font-semibold">URGE TALKS LTD</span></p>
                <p>Bank Name: <span className="font-semibold">ACCESS BANK</span></p>
                <p>Account Number: <span className="font-semibold">1800264258</span></p>
                <div className="flex justify-between">
                  <button className="py-4 px-8 rounded-full border border-sky-blue text-sky-blue" onClick={back}>Back</button>
                  <button className="py-4 px-8 rounded-full bg-sky-blue text-black font-semibold" onClick={proceed}>Proceed</button>
                </div>
              </div>
            </>
          )
          : screen === "form"? (
            <>
              <div className="flex flex-col gap-3.5 items-center">
                <h3 className="text-white uppercase text-2xl font-extrabold">
                  Upload Video
                </h3>
                <p className="text-white font-semibold text-center">
                  Fill in your details below to upload your video
                </p>
              </div>
              <form
                className="text-white w-auto md:w-[500px] md:p-4 flex flex-col mt-5 gap-5"
                onSubmit={saveFormData}
              >
                <Input
                  name="first_name"
                  type="text"
                  label="First Name"
                  placeholder="John"
                  value={formData.first_name}
                  onChange={formDataChangeHandler}
                  t2
                />
                <Input
                  name="last_name"
                  type="text"
                  label="Last Name"
                  placeholder="Daniel"
                  value={formData.last_name}
                  onChange={formDataChangeHandler}
                  t2
                />
                <Input
                  name="email"
                  type="email"
                  label="Email Address"
                  placeholder="example@example.com"
                  value={formData.email}
                  onChange={formDataChangeHandler}
                  t2
                />
                <Input
                  name="referral"
                  type="text"
                  label="Referral Code"
                  placeholder="Optional"
                  value={formData.referral}
                  onChange={formDataChangeHandler}
                  t2
                  notRequired
                />
                <FreeBtn
                  classes="text-black"
                  type="submit"
                >
                  Proceed To Make Payment
                </FreeBtn>
              </form>
            </>
          ): null}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Competition;
