import React from "react";
import { SmButton } from "../button";
import { useNavigate } from "react-router-dom";

const PriceCard = ({tag, price, save}) => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col gap-6 px-10 py-8 md:px-20 md:py-16 items-center bg-off-black rounded-3xl">
      <h1 className="uppercase text-sky-blue font-[700] text-lg lg:text-2xl">{tag}</h1>
      <p className="text-white font-[800] text-2xl lg:text-3xl flex flex-col items-center">
        <span className="block text-6xl lg:text-8xl">${price}</span> <span>per month</span>
        <span className={`font-[600] text-xl lg:text-2xl block text-center ${!save && "invisible"}`}>Save {save}</span>
      </p>
      <SmButton onClick={()=> navigate("/p/subscribe")}>Subscribe</SmButton>
    </div>
  );
};

export default PriceCard;
