import {
  UserAxiosInstance,
  AuthUserAxiosInstance,
  FileUploadAxiosInstance,
} from "./instance";

export const Api = {
  auth: {
    signInEmail: async (data) => UserAxiosInstance.post("/login", data),
    refreshToken: async (data) => UserAxiosInstance.post("/refresh", data),
  },
  anon: {
    getLandingPageData: async () => UserAxiosInstance.get("/anon/landing"),
  },
  competition: {
    submitEntry: async (data) =>
      FileUploadAxiosInstance.post("/competition", data),
  },
  stripe: {
    getPricing: async () => UserAxiosInstance.get("/stripe/get-prices"),
  },
  subscription: {
    getSubscriptions: async () =>
      AuthUserAxiosInstance.get("/stripe/subscriptions"),
  },
  getJobEndpoint: {
    getJobs: async () => UserAxiosInstance.get("/jobs"),
  },
  
  getMyJobEndpoint: {
    myJob: async () => AuthUserAxiosInstance.get("/myjob"),
  },
  applyforJobEndPoint: {
    apply: async (data) =>
      FileUploadAxiosInstance.post(`posts/${3}/apply`, data),
  },
};

