import React from "react";

const Privacy = () => {
  return (
    <div className="text-white bg-bg px-14 py-14 flex justify-center leading-7">
      <div className="lg:w-1/2">
        <h1 className="text-xl text-sky-blue font-bold mb-1">Privacy Policy</h1>
        <h3 className="text-sm text-gray-500 mb-4 font-medium">
          Last Updated: 18th August, 2023
        </h3>
        <h1 className="text-3xl font-black text-sky-blue mb-2">
          Welcome to Urge!
        </h1>
        <p className="font-bold mb-4">
          This Privacy Policy is intended to help you understand how we collect,
          use, and safeguard your personal information when you use our mobile
          application ("App").
        </p>
        <ol className="ml-8 list-decimal flex flex-col gap-2 text-justify font-semibold">
          <li>
            Introduction
            <span className="block font-normal my-1 text-gray-300">
              Urge is a network focused on amplifying voices and expressions
              through short, impactful videos and talks. Our platform strives to
              share great ideas and perspectives with the world.
            </span>
          </li>
          <li>
            Information We Collect
            <span className="block font-normal my-1 text-gray-300">
              We collect certain information to provide you with access to our
              platform and improve your experience. The information we collect
              includes:
              <ul className="my-2 ml-4 flex flex-col gap-2 list-disc">
                <li>Personal Information: Name, email, display picture.</li>
                <li>
                  Basic App Data: Information required for the proper
                  functioning of the App.
                </li>
              </ul>
              We adhere to strict privacy guidelines and take your data
              protection seriously.
            </span>
          </li>
          <li>
            How We Use Your Information
            <span className="block font-normal my-1 text-gray-300">
              We only use the collected data for the purposes it was intended,
              which includes:
              <ul className="my-2 ml-4 flex flex-col gap-2 list-disc">
                <li>Providing access to our videos and talks.</li>
                <li>Enhancing your user experience on the App.</li>
                <li>Sending you relevant notifications.</li>
                <li>Complying with legal obligations.</li>
              </ul>
            </span>
          </li>
          <li>
            Subscription and Payment
            <span className="block font-normal my-1 text-gray-300">
              To access our premium content, we offer a subscription plan priced
              at $20 per month. Payment for the subscription is processed
              securely through Stripe. Please note that we are not a third-party
              payment processor.
            </span>
          </li>
          <li>
            User-Generated Content
            <span className="block font-normal my-1 text-gray-300">
              User-generated content plays an important role on our platform.
              Your shared content may be visible to other users. We are
              committed to maintaining a positive environment and will remove
              explicit content that is immoral, illegal, or disruptive to other
              users.
            </span>
          </li>
          <li>
            Data Security
            <span className="block font-normal my-1 text-gray-300">
              We prioritize the security of your information and employ
              industry-standard measures to protect it. This includes encryption
              for data transmission and storage.
            </span>
          </li>
          <li>
            User Rights
            <span className="block font-normal my-1 text-gray-300">
              We respect your rights concerning your data. You have the right
              to:
              <ul className="my-2 ml-4 flex flex-col gap-2 list-disc">
                <li>Access the personal information we hold about you.</li>
                <li>Correct or update inaccurate information.</li>
                <li>Delete your account and data.</li>
              </ul>
              You can exercise these rights by contacting us using the information provided in Section 11.
            </span>
          </li>
          <li>
            Children's Privacy
            <span className="block font-normal my-1 text-gray-300">
            Urge is not intended for use by children under a
            certain age. We do not knowingly collect personal information from
            children. If you believe your child has provided us with their
            information, please contact us immediately.
            </span>
          </li>
          <li>
            Changes to Privacy Policy
            <span className="block font-normal my-1 text-gray-300">
            We may update this Privacy Policy from time
            to time. Any changes will be communicated through the App, and the
            revised policy will be effective upon posting.
            </span>
          </li>
          <li>
            Contact Information
            <span className="block font-normal my-1 text-gray-300">For questions, concerns, or requests related to
            your privacy, please contact us at  <a href="mailto:support@urgetalks.com" className="text-sky-blue">support@urgetalks.com</a>
            </span>
          </li>
          <li>
            Consent
            <span className="block font-normal my-1 text-gray-300">
            By using the Urge App, you consent to the practices outlined
            in this Privacy Policy. Thank you for using Urge and trusting us with
            your information.
            </span>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default Privacy;
