import { useMutation, useQuery } from "react-query";
import { toast } from "react-hot-toast";
import { Api } from "../api/api";
import { handleError } from "../utils/utils";


export const useApplyJob = () => {
  return useMutation(
    (data) => Api.applyforJobEndPoint.apply(data),
    {
      onSuccess: ({ data }) => {
        toast.success(data.message);
      },
      onError: (err) => {
        console.log(err, "error from apply");

        // Extract the error message
        let errorMessage = "An error occurred. Please try again.";

        if (err.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          errorMessage = err.response.data.message || errorMessage;
        } else if (err.request) {
          // The request was made but no response was received
          errorMessage = "No response received from the server. Please check your network connection.";
        } else {
          // Something happened in setting up the request that triggered an Error
          errorMessage = err.message;
        }

        toast.error(errorMessage);
      },
    }
  );
};


// export const useLogin = () => {
//   const setUserData = useSetRecoilState(userAtom);
//   return useMutation((data) => Api.auth.signInEmail(data), {
//     onSuccess: ({ data }) => {
//       setUserData(data.data);
//       toast.success(data.message);
//     },
//     onError: (err) => {
//       toast.error(err);
//       // handleToastError(err);
//     },
//   });
// };




export const useGetJobPosting = () => {
  return useQuery(["jobs"], async () => await Api.getJobEndpoint.getJobs(), {
    onError: (err) => {
      handleError(err);
    },
  });
};


export const useGetJobsICreated = () => {
  return useQuery(["myjobs"], async () => await Api.getMyJobEndpoint.myJob(), {
    onError: (err) => {
      // handleError(err);
    },
  });
};
