import React, { useState } from "react";
import { useApplyJob } from "../../hooks/job.hook";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useRecoilValue } from "recoil";
import userAtom from "../../atoms/user.atom";

const ApplicationForm = ({ id, closeModal }) => {
  const { mutate, isLoading } = useApplyJob();
  const userData = useRecoilValue(userAtom);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    resume: null,
    phone: "",
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (name === "resume" && files[0]) {
      const fileSize = files[0].size / 1024 / 1024; // in MB
      if (fileSize > 1) {
        toast.error("File size exceeds 1MB. Please upload a smaller file.");
        return;
      }
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: files ? files[0] : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const applicationData = new FormData();
    applicationData.append('job_post', id);
    applicationData.append('applicant_name', formData.name);
    applicationData.append('applicant_email', formData.email);
    applicationData.append('phone_number', formData.phone);
    applicationData.append('resume', formData.resume);

    mutate(applicationData, {
      onSuccess: () => {
        toast.success("Application submitted successfully!!!");
        closeModal();
      },
      onError: (error) => {
        toast.error("Failed to submit application. Please try again.");
      },
    });
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
        <h2 className="text-2xl font-bold mb-4">Apply for this job</h2>
        <form encType="multipart/form-data" onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700">Name:</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Email:</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Phone:</label>
            <input
              type="text"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Resume:</label>
            <input
              type="file"
              name="resume"
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded"
              required
            />
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              onClick={closeModal}
              className="bg-gray-500 text-white px-4 py-2 rounded mr-2 hover:bg-gray-600"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              disabled={isLoading}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ApplicationForm;
