import { NavLink } from "react-router-dom";

const NavItem = ({ title, link, setShowNav, onClick, newpage}) => {
  return (
    <NavLink
      to={link}
      {...(newpage ? { target: "_blank" } : {})}
      className={({ isActive, isPending }) =>
        isActive ? "text-sky-blue font-[700] text-sm md:text-base whitespace-nowrap" : "text-white font-[700] text-sm md:text-base whitespace-nowrap"
      }
      onClick={() => {
        setShowNav && setShowNav(false);
        onClick && onClick();
      }}
    >
      {title}
    </NavLink>
  );
};

export default NavItem;

export const NavBtn = ({ title, link, setShowNav, onClick }) => {
  return (
    <NavLink
      to={link}
      className="bg-sky-blue py-3 px-7 rounded-3xl font-[700] text-sm md:text-base"
      onClick={() => {
        setShowNav && setShowNav(false);
        onClick && onClick();
      }}
    >
      {title}
    </NavLink>
  );
};
