import React from "react";
import Nav from "../../components/nav";
import Footer from "../../components/footer";
import Kanyo from "../../assets/images/kanayo.jpg";
import Richard from "../../assets/images/richard.jpg";
import Malik from "../../assets/images/malik.jpg";
import Attach from '../../assets/images/attah.jpg'
const dummyImage = "https://via.placeholder.com/150";

const teamData = {
  tech: [
    {
      name: "Anayo Modestus Onyekwere ",

      image: Kanyo,
      biography: `

      Anayo Modestus Onyekwere 
      
      popularly known as Kanayo O. Kanayo is a Nigerian veteran actor and lawyer.
      
      he won the Africa Movie Academy Award for Best Actor in a Leading Role for his performance in the movie Family Battle.
      
      Anayo Modestus Onyekwere partnered with Tony Akwaba in writing one of his most intriguing books “The testator” which he was invited to share the synopsis on the urge talks platform
      
      His video can be streamed for free on the urge app and YouTube page      `,
      watch: "https://youtu.be/u4XkQCdlN00?si=WBjF_TtZdemehUP6",
    },
    {
      name: "Mirsada Memic",

      image: dummyImage,
      biography: `Mirsada Memic is a Life coach and a public speaker who is focused helping women transform their mindset and cope with uncertainty in life.

      Mirsada Memic is located in Boston, Massachusetts United States and is a mother of two
      
      Mirsada Memic’s videos can be found on the urge app and YouTube page. `,
      watch: "https://youtu.be/ayU9oN1pLUM?si=OjVbdumgxRCj9bzq",
    },
    {
      name: "Richard Barrett",

      image: Richard,
      biography: `Richard Barrett- a US military in the national guard, also a minister in the church of christ in Virginia.

        Richard Barrett was invited to speak on urge on his understanding of Gods view of laziness and how that can transform into depression.
        
        His video is uploaded for free on the urge app and YouTube page.
`,
      watch: "https://youtu.be/rZ0FAHToi4k?si=KqNoZc7HRJaLk0wg",
    },
  ],
  speakers: [
    {
      name: "Dr Amir Rashildian",
      image: dummyImage,
      description:
        "Alice brings insightful and engaging presentations to our events, captivating audiences with her expertise.",
      biography: `Holding Bachelor of Science degrees in Chemistry and Human Biology and a Doctorate in Chiropractic, Amir A. Rashidian is the founder of the Mid-Atlantic Chiropractic Center. Established in 2006 and serving over 18,000 patient visits annually, the Mid-Atlantic Chiropractic Center focuses on high-tech diagnostics to detect and correct disturbances in the nervous system. The doctors at the Mid-Atlantic Chiropractic Center, promoting drugless health solutions, work with patients to eliminate the causes of disease and not merely the suppression of symptoms.

      As a consultant, Dr. Rashidian has worked with 18 chiropractic practices, 16 of which were startup businesses that all reached profitability within the first 3 months of a business launch. Dr. Rashidian attributes his success in opening practices to an intensive systematized marketing plan implemented 3 months prior to business launch. He has personally opened two very successful chiropractic practices.`,
    },
    {
      name: "Abdoul M. Malik ",
      role: "Guest Speaker",
      location: "Africa",
      image: Malik,
      biography: `ABDOUL MALIK is an Author of two major books, “profit for prophet” and “own yourself “.
      He is young, vibrant, intelligent and maybe single 
       
      Here is what he said:
      UNLOCK THE KEYS TO YOUR INNER POWER
      In our current society many of us enjoy instant gratification. I am a firm believer that delayed gratification is the key to greatness. Great works done takes a level of handwork, dedication, and focus. Profit 4 Prophet is your source for success through mastering personal development in your life. This book serves to bring out the latent potential that lies within us all. Greatness is our birthright. We are all meant to shine like the stars in the sky. 
      
      His video is uploaded for free on the urge app and YouTube page.`,
    },
    {
      name: "Dr. Eddie Attah ",
      role: "Guest Speaker",
      location: "Africa",
      image: Attach,
      biography: `Bio coming soon`,
      watch: "https://youtu.be/ibPcss940Lk?si=LHGzzeG0-tLc17Cn",
    },
  ],
};

const TeamSection = ({ title, team }) => (
  <section className="mb-12 w-full">
    <h2 className="text-2xl font-semibold text-blue-700 mb-4 text-center">
    
    </h2>
    <div className="flex flex-wrap justify-center gap-8">
      {team.map((member, index) => (
        <div
          key={index}
          className="w-80 p-4 bg-gray-800 rounded-lg text-center"
        >
          <img
            src={member.image}
            alt={member.name}
            className="w-full h-48 object-cover rounded-t-lg mb-4"
          />
          <h3 className="text-lg font-bold">{member.name}</h3>
          <p className="text-gray-300 mt-2 text-sm">{member.biography}</p>
          <a className="mb-4" href={member.watch}>
            Click here to check it out on YouTube
          </a>
        </div>
      ))}
    </div>
  </section>
);

const TeamPage = () => {
  return (
    <>
      <div className="bg-bg px-7 md:px-14 pt-9">
        <Nav />
        <div className="text-white mt-20 flex flex-col items-center gap-8 w-full">
          <header className="mb-12 text-center w-96">
            <h1 className="text-4xl font-bold">Meet the Urgetalks Team</h1>
            <p className="mt-4 text-lg">
              Our dedicated team members in Africa and the USA are here to make
              every event successful.
            </p>
          </header>

          <TeamSection title="Tech Team" team={teamData.tech} />
          <TeamSection title="Speakers Team" team={teamData.speakers} />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TeamPage;
