import React, { useState, useEffect, Fragment } from "react";
import { useRecoilValue } from "recoil";
import userAtom from "../../atoms/user.atom";
import { ProductDisplay, SuccessDisplay } from "../../components/stripe";
import { useGetPricing } from "../../hooks/stripe.hook";
import Loader from "../../components/Loader";
import { Nav2 } from "../../components/nav";
import Cancel from "../../assets/icons/cancel";
import { SmButton } from "../../components/button";
import { useNavigate } from "react-router-dom";

const Message = ({ message }) => (
  <section>
    <p>{message}</p>
  </section>
);

export default function StripeApp() {
  let [message, setMessage] = useState("");
  let [success, setSuccess] = useState(false);
  let [sessionId, setSessionId] = useState("");
  let [plan, setPlan] = useState("");
  const { data, isFetching } = useGetPricing();
  const userData = useRecoilValue(userAtom);
  const [member, setMember] = useState(userData.user.is_member);
  const navigate = useNavigate();

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      console.log("success")
      setSuccess(true);
      setSessionId(query.get("session_id"));
      setPlan(query.get("plan"))
    }

    if (query.get("canceled")) {
      setSuccess(false);
      setMessage(
        "Subscription canceled"
      );
    }
  }, [sessionId]);

  if (!success && message === "") {
    return (
      <div className="bg-bg min-h-screen">
        <Nav2/>
        {isFetching ? (
          <Loader />
        ) : (
          <div className="flex gap-4 md:gap-8 justify-center mt-11 md:mt-16 flex-wrap">
            {data?.data?.prices.map((item) => (
              <Fragment key={item.id}>
              <ProductDisplay plan={item.recurring.interval} amount={item.unit_amount} lookup_key={item.lookup_key} member={member}/>
              </Fragment>
            ))}
          </div>
        )}
        {member && <div className="flex justify-center mt-10 text-sky-blue"><p>You are an insider</p></div>}
      </div>
    );
  } else if (success && sessionId !== "") {
    return (
    <div className="bg-bg min-h-screen">
      <Nav2/>
      <div className="flex gap-4 md:gap-8 justify-center mt-11 md:mt-16">
        <SuccessDisplay plan={plan}/>
      </div>
      
    </div>
    )
  } else {
    return (
      <div className="bg-bg min-h-screen text-white">
        <Nav2/>
        <div className="flex flex-col gap-4 md:gap-8 justify-center mt-11 md:mt-16 items-center">
          <div className="bg-off-black rounded-3xl px-4 py-4 md:px-8 md:py-8 ">
            <div className="flex text-red-600 justify-center">
            <Cancel w="w-20 md:w-40"
              h="h-20 md:h-40"/>
          </div>
          <Message message={message} />
          </div>
          <SmButton onClick={()=> navigate("/p/dashboard")}>Back</SmButton>
          
        </div>
        
      </div>
      )
  }
}
