import axios from "axios";
import { USER_API_URL } from "../utils/constants";
import { Auth } from "../utils/storage";

export const UserAxiosInstance = axios.create({
  baseURL: USER_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

UserAxiosInstance.interceptors.response.use(
  function (response) {
    return Promise.resolve(response);
  },
  function (error) {
    if (error.response?.data?.message) {
      return Promise.reject(error.response?.data?.message);
    }
    return Promise.reject(error?.message);
  }
);

export const AuthUserAxiosInstance = axios.create({
  baseURL: USER_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

AuthUserAxiosInstance.interceptors.request.use(
  async (config) => {
    // Ensure you don't overwrite existing headers but merge them
    config.headers = {
      ...config.headers,  // Keep existing headers
      Accept: "application/json",
      Authorization: `Bearer ${Auth.getToken()}`,  // Ensure 'Authorization' header is correctly set
    };
    config.timeout = 120000;
    console.log(config,'1101010101010100110100101001  ')
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

AuthUserAxiosInstance.interceptors.response.use(
  function (response) {
    return Promise.resolve(response);
  },
  function (error) {
    if (error.response?.data?.message) {
      return Promise.reject(error.response?.data?.message);
    }
    return Promise.reject(error?.message);
  }
);


export const FileUploadAxiosInstance = axios.create({
  baseURL: USER_API_URL,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

FileUploadAxiosInstance.interceptors.response.use(
  function (response) {
    return Promise.resolve(response);
  },
  function (error) {
    if (error.response?.data?.message) {
      return Promise.reject(error.response?.data?.message);
    }
    return Promise.reject(error?.message);
  }
);
