import React from "react";
import Nav from "../../components/nav";
import Footer from "../../components/footer";
import Button from "../../components/button";
import b1 from "../../assets/images/b1.png";
import b2 from "../../assets/images/b2.png";
import b3 from "../../assets/images/b3.png";

const Download = () => {
  return (
    <>
      <div className="bg-bg px-7 md:px-14 pt-9">
        <Nav />
        <div className="text-white mt-20 flex flex-col items-center gap-8">
          <h2 className="font-[700] xl:w-[1000px] text-3xl text-center leading-13">
            Download the <span className="text-sky-blue">Urge app</span> now and
            start watching powerful videos that will transform your mind
          </h2>
          <div className="flex gap-8 md:gap-16 lg:gap-20 text-black flex-wrap justify-center">
            <a
              href="https://play.google.com/store/apps/details?id=com.urgetalks.urge"
              target="_blank" rel="noopener noreferrer"
            >
              <Button>DOWNLOAD FOR ANDROID</Button>
            </a>
            <a href="https://apps.apple.com/us/app/urge/id6474566609" target="_blank" rel="noopener noreferrer">
              <Button>DOWNLOAD FOR IOS</Button>
            </a>
          </div>
        </div>
      </div>
      <div className="bg-bg flex gap-5 pt-16 justify-center overflow-hidden">
        <img
          src={b1}
          alt="Urge Mobile"
          className="object-none self-end"
        />
        <img
          src={b2}
          alt="Urge Mobile"
          className="object-cover"
        />
        <img
          src={b3}
          alt="Urge Mobile"
          className="object-none self-end"
        />
      </div>
      <Footer />
    </>
  );
};

export default Download;


