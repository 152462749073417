import React from "react";
import { Nav2 } from "../../components/nav";
import Footer from "../../components/footer";
import Button from "../../components/button";
import { useNavigate } from "react-router-dom";
import { useGetSubscription } from "../../hooks/subscription.hook";
import userAtom from "../../atoms/user.atom";
import { useRecoilValue } from "recoil";
import { USER_API_URL } from "../../utils/constants";
import { Auth } from "../../utils/storage";
import moment from "moment";
import { LoaderIcon } from "react-hot-toast";

const Dashboard = () => {
  const navigate = useNavigate();
  const { data, isFetching } = useGetSubscription();
  const userData = useRecoilValue(userAtom);
  console.log(data?.data?.data)
  return (
    <div className="h-screen bg-bg flex flex-col justify-between">
      <div className="px-7 md:px-14 pt-9 text-white flex flex-col">
        <Nav2 />
        <h1 className="text-2xl font-extrabold md:text-4xl my-7">
          All Subscriptions
        </h1>
        {isFetching ? (
          <div className="flex justify-center mt-7 text-black">
            <LoaderIcon />
          </div>
        ) : (
          <table className="overflow-auto flex flex-col flex-1">
            <thead className="flex-1">
              <tr className="bg-off-black min-w-max flex rounded-xl">
                <th className="px-3.5 py-5 border-r border-offish flex-none min-w-[35px] w-16 text-left">
                  S/N
                </th>
                <th className="px-3.5 py-5 border-r border-offish flex-1 text-left min-w-[140px]">
                  Subscription
                </th>
                <th className="px-3.5 py-5 border-r border-offish flex-1 text-left min-w-[140px]">
                  Activated
                </th>
                <th className="px-3.5 py-5 flex-1 text-left min-w-[140px]">
                  Expires
                </th>
              </tr>
            </thead>

            <tbody>
              {data?.data?.data.map((item, index) => (
                <tr
                  className="flex"
                  key={index}
                >
                  <td className="px-3.5 py-5 rounded-l-xl border-r border-offish flex-none min-w-[35px] w-16">
                    {index + 1}
                  </td>
                  <td className="px-3.5 py-5 rounded-l-xl border-r border-offish flex-1 min-w-[140px] capitalize">
                    {item.interval + "ly"}
                  </td>
                  <td className="px-3.5 py-5 rounded-l-xl border-r border-offish flex-1 min-w-[140px]">
                    {moment.unix(item.billing_start_date).format('MMMM D, YYYY')}
                  </td>
                  <td className="px-3.5 py-5 rounded-l-xl flex-1 min-w-[140px]">
                    {moment.unix(item.billing_end_date).format('MMMM D, YYYY')}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        <div className="flex justify-center mt-7 text-black">
          {userData.user.is_member ? (
            <form
              action={`${USER_API_URL}stripe/manage-subscription`}
              method="POST"
            >
              <input
                type="hidden"
                name="token"
                value={Auth.getToken()}
              />
              <Button type="submit">Manage Subscription</Button>
            </form>
          ) : (
            <Button onClick={() => navigate("/p/subscribe")}>Subscribe</Button>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Dashboard;
