import { Api } from "../api/api";
import {handleError} from "../utils/utils";
import { useQuery } from "react-query";

export const useGetPricing = () => {
  return useQuery(
    ["pricing"],
    async () => await Api.stripe.getPricing(),
    {
      onError: (err) => {
        handleError(err);
      },
    }
  );
};