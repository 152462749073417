import React from "react";
import { USER_API_URL } from "../../utils/constants";
import { SmButton } from "../button";
import Check from "../../assets/icons/check";
import { Auth } from "../../utils/storage";

export const ProductDisplay = ({ plan, amount, lookup_key, member }) => {
  return (
    <section>
      <div className="flex flex-col gap-6 px-10 py-8 md:px-20 md:py-16 items-center bg-off-black rounded-3xl">
        <h1 className="uppercase text-sky-blue font-[700] text-lg lg:text-2xl">
          {plan}ly
        </h1>
        <p className="text-white font-[800] text-2xl lg:text-3xl flex flex-col items-center">
          <span className="block text-6xl lg:text-8xl">${amount / 100}</span>{" "}
          <span>per {plan}</span>
        </p>
        <form
          action={`${USER_API_URL}stripe/insider-subscription`}
          method="POST"
        >
          <input
            type="hidden"
            name="lookup_key"
            value={lookup_key}
          />
          <input
            type="hidden"
            name="token"
            value={Auth.getToken()}
          />
          <input
            type="hidden"
            name="plan"
            value={`${plan}ly`}
          />
          <SmButton
            id="checkout-and-portal-button"
            type="submit"
            disabled={member}
          >
            Checkout
          </SmButton>
        </form>
      </div>
    </section>
  );
};

export const SuccessDisplay = ({ plan }) => {
  return (
    <section className="text-white">
      <div className="product Box-root">
        <div className="flex flex-col ml-1 mr-1 gap-6 px-8 py-8 md:px-14 md:py-14 items-center bg-off-black rounded-3xl text-center">
          <div className="flex text-sky-blue">
            <Check
              w="w-20 md:w-40"
              h="h-20 md:h-40"
            />
          </div>
          <h3 className="font-[800] text-lg md:text-2xl">
            Subscription to {plan} plan successful!
          </h3>
        </div>
      </div>
      <form
        action={`${USER_API_URL}stripe/manage-subscription`}
        method="POST"
        className="flex justify-center mt-10"
      >
        <input
          type="hidden"
          name="token"
          value={Auth.getToken()}
        />
        <button
          id="checkout-and-portal-button"
          type="submit"
          className="text-sky-blue font-bold md:font-black"
        >
          Manage your billing information
        </button>
      </form>
    </section>
  );
};
