import React from "react";

const Button = ({children, ...props}) => {

    return(
        <button className="px-9 py-5 bg-sky-blue rounded-4xl font-[700] w-full sm:w-[380px]" {...props}>{children}</button>
    );
};

export default Button;

export const SmButton = ({children, ...props}) => {
    return(
        <button className="px-9 py-5 bg-sky-blue rounded-4xl font-[700] w-[240px] text-sm lg:text-base disabled:bg-gray-700" {...props}>{children}</button>
    )
}

export const FreeBtn = ({children, classes, ...props}) => {
    return (
        <button className={`px-9 py-5 bg-sky-blue rounded-4xl font-[700] ${classes}`} {...props}>{children}</button>
    )
}
