import React from "react";

const RegisterIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
    >
      <path
        d="M16.25 20.5833C16.25 18.1891 13.3402 16.25 9.75 16.25C6.15983 16.25 3.25 18.1891 3.25 20.5833M20.5833 17.3333V14.0833M20.5833 14.0833V10.8333M20.5833 14.0833H17.3333M20.5833 14.0833H23.8333M9.75 13C8.60073 13 7.49853 12.5434 6.68587 11.7308C5.87321 10.9181 5.41667 9.81592 5.41667 8.66665C5.41667 7.51737 5.87321 6.41517 6.68587 5.60252C7.49853 4.78986 8.60073 4.33331 9.75 4.33331C10.8993 4.33331 12.0015 4.78986 12.8141 5.60252C13.6268 6.41517 14.0833 7.51737 14.0833 8.66665C14.0833 9.81592 13.6268 10.9181 12.8141 11.7308C12.0015 12.5434 10.8993 13 9.75 13Z"
        stroke="white"
        strokeWidth="1.83333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RegisterIcon;
