import React from "react";
import Nav from "../../components/nav";
import Footer from "../../components/footer";
import PriceCard from "../../components/card";

const Pricing = () => {
  return (
    <>
      <div className="bg-bg px-7 md:px-14 pt-9">
        <Nav />
        <div className="text-white mt-20 flex flex-col items-center gap-4">
          <h2 className="font-[800] text-4xl lg:text-5xl text-center">
            Become an <span className="text-sky-blue">Insider</span> on Urge app
          </h2>
          <p className="lg:leading-10 text-lg lg:text-2xl text-center lg:w-[800px]">
            Pay for Urge Premium and get unlimited access to all the
            masterclasses and events on Urge.
          </p>
        </div>
        <div className="flex gap-4 md:gap-8 justify-center mt-11 md:mt-16 flex-wrap">
          <PriceCard
            tag="monthly"
            price="20"
          />
          <PriceCard
            tag="annually"
            price="17"
            save="20%"
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Pricing;
