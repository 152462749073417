import React from "react";
import { TailSpin } from "react-loader-spinner";

const Loader = () => {
  return (
    <div className="w-full h-full flex items-center justify-center mt-6">
      <TailSpin
        width={40}
        height={40}
        color="#00B4D7"
      />
    </div>
  );
};

export default Loader;

export const Spinner = () => {
  return (
    <div className="w-full h-full flex items-center justify-center">
      <TailSpin
        width={30}
        height={30}
        color="#00B4D7"
      />
    </div>
  );
};

export const PageLoader = ({ loader }) => {
  if (!loader) {
    return null;
  }
  return (
    <div className="w-screen h-screen flex items-center justify-center fixed top-0 left-0 bg-black opacity-30 z-40">
      <TailSpin
        width={40}
        height={40}
        color="#00B4D7"
      />
    </div>
  );
};
