import React from "react";
import Nav from "../../components/nav";
import Footer from "../../components/footer";
import b1 from "../../assets/images/b1.png";
import b2 from "../../assets/images/b2.png";
import b3 from "../../assets/images/b3.png";

const SpeakNow = () => {
  return (
    <>
      <div className="bg-bg px-4 sm:px-7 md:px-14 pt-9">
        <Nav />
        <div className="text-white mt-12 sm:mt-20 flex flex-col items-center gap-6 sm:gap-8">
          <header className="mb-8 sm:mb-12 text-center">
            <h1 className="text-3xl sm:text-4xl font-bold">
              How to Submit a Speaking Proposal on Urgetalks
            </h1>
            <p className="mt-4 text-base sm:text-lg">
              We are excited to help you share your knowledge and insights with
              our audience. Follow these steps to submit your speaking proposal
              and prepare for your talk.
            </p>
          </header>



          <section className="mb-6 sm:mb-8">
            <h2 className="text-xl sm:text-2xl font-semibold text-blue-500 mb-4">
             
       Remote , You can speak from the comfort of your house
            </h2>
            <div className="space-y-4 ml-5 sm:ml-6">
             
              <h3 className="text-lg sm:text-xl font-semibold"></h3>
             
              <p className="text-base sm:text-lg">
              From every corner of the globe, Urge amplifies your voice, turning ideas into impact.
              </p>
              <p className="text-base sm:text-lg">
                For any inquiries regarding your speaking proposal or if you
                need assistance with directions, please contact our office at{" "}
                <a href="tel:+1234567890" className="text-blue-600 underline">
                  +1 (234) 567-890
                </a>{" "}
                or email us at{" "}
                <a href="mailto:info@urgetalks.com" className="text-blue-600 underline">
                  info@urgetalks.com
                </a>.
              </p>
            </div>
          </section>
          <section className="mb-6 sm:mb-8">
            <h2 className="text-xl sm:text-2xl font-semibold text-blue-500 mb-4">
              Step 1: Submit a Speaking Proposal
            </h2>
            <ol className="list-decimal list-inside space-y-2 ml-5 sm:ml-6">
              <li className="flex flex-col sm:flex-row items-start">
                <span className="font-semibold text-lg">Click on the link:</span>
                <p className="ml-2 text-base sm:text-lg">
                  <a
                    href="https://docs.google.com/forms/d/10zGQUfTHd7Qj3LE6rbWH-ReImvOaGi0ustp_YZeNVQk/viewform?edit_requested=true"
                    className="text-blue-600 underline break-all"
                  >
                    Speak Now Form
                  </a>
                  .
                </p>
              </li>
              <li className="flex flex-col sm:flex-row items-start">
                <span className="font-semibold text-lg">Proposal Form:</span>
                <p className="ml-2 text-base sm:text-lg">
                  Click on the "Submit Proposal" button.
                </p>
              </li>
              <li className="flex flex-col sm:flex-row items-start">
                <span className="font-semibold text-lg">Fill in the Form:</span>
                <p className="ml-2 text-base sm:text-lg">
                  Provide the title of your talk, a brief description, your
                  target audience, and any special requirements.
                </p>
              </li>
              <li className="flex flex-col sm:flex-row items-start">
                <span className="font-semibold text-lg">Submit:</span>
                <p className="ml-2 text-base sm:text-lg">
                  Review your proposal and click the "Submit" button.
                </p>
              </li>
            </ol>
          </section>

      

          <section className="mb-6 sm:mb-8">
            <h2 className="text-xl sm:text-2xl font-semibold text-blue-500 mb-4">
              Step 2: Await Approval
            </h2>
            <ol className="list-decimal list-inside space-y-3 ml-5 sm:ml-6">
              <li className="flex flex-col sm:flex-row items-start">
                <span className="font-semibold text-lg">Notification:</span>
                <p className="ml-2 text-base sm:text-lg">
                  You will receive an email confirming the receipt of your
                  proposal.
                </p>
              </li>
              <li className="flex flex-col sm:flex-row items-start">
                <span className="font-semibold text-lg">Review Process:</span>
                <p className="ml-2 text-base sm:text-lg">
                  The Urgetalks team will review your proposal. This process may
                  take a few days.
                </p>
              </li>
              <li className="flex flex-col sm:flex-row items-start">
                <span className="font-semibold text-lg">Approval or Feedback:</span>
                <p className="ml-2 text-base sm:text-lg">
                  You will receive an email with either an approval or feedback
                  for revisions.
                </p>
              </li>
            </ol>
          </section>

          <section className="mb-6 sm:mb-8">
            <h2 className="text-xl sm:text-2xl font-semibold text-blue-500 mb-4">
              Step 3: Prepare for Your Talk
            </h2>
            <ol className="list-decimal list-inside space-y-3 ml-5 sm:ml-6">
              <li className="flex flex-col sm:flex-row items-start">
                <span className="font-semibold text-lg">Confirm Details:</span>
                <p className="ml-2 text-base sm:text-lg">
                  Once approved, confirm the date, time, and format of your talk
                  with the Urgetalks team.
                </p>
              </li>
              <li className="flex flex-col sm:flex-row items-start">
                <span className="font-semibold text-lg">Prepare Your Material:</span>
                <p className="ml-2 text-base sm:text-lg">
                  Create your presentation slides, gather any necessary
                  materials, and practice your talk.
                </p>
              </li>
              <li className="flex flex-col sm:flex-row items-start">
                <span className="font-semibold text-lg">Technical Check:</span>
                <p className="ml-2 text-base sm:text-lg">
                  Ensure you have a reliable internet connection, a working
                  microphone, and camera.
                </p>
              </li>
            </ol>
          </section>

          <section className="mb-6 sm:mb-8">
            <h2 className="text-xl sm:text-2xl font-semibold text-blue-500 mb-4">
              Step 4: Deliver Your Talk
            </h2>
            <ol className="list-decimal list-inside space-y-3 ml-5 sm:ml-6">
              <li className="flex flex-col sm:flex-row items-start">
                <span className="font-semibold text-lg">Log In Early:</span>
                <p className="ml-2 text-base sm:text-lg">
                  Log in to the platform at least 15 minutes before your
                  scheduled time.
                </p>
              </li>
              <li className="flex flex-col sm:flex-row items-start">
                <span className="font-semibold text-lg">Check-In:</span>
                <p className="ml-2 text-base sm:text-lg">
                  Check in with the event coordinator or tech support to ensure
                  everything is set up correctly.
                </p>
              </li>
              <li className="flex flex-col sm:flex-row items-start">
                <span className="font-semibold text-lg">Deliver Your Talk:</span>
                <p className="ml-2 text-base sm:text-lg">
                  Follow the scheduled format and engage with your audience.
                </p>
              </li>
              <li className="flex flex-col sm:flex-row items-start">
                <span className="font-semibold text-lg">Q&A Session:</span>
                <p className="ml-2 text-base sm:text-lg">
                  Be prepared to answer questions from the audience after your
                  talk.
                </p>
              </li>
            </ol>
          </section>

          <section className="mb-6 sm:mb-8">
            <h2 className="text-xl sm:text-2xl font-semibold text-blue-500 mb-4">
              Step 5: Post-Talk Follow-Up
            </h2>
            <ol className="list-decimal list-inside space-y-3 ml-5 sm:ml-6">
              <li className="flex flex-col sm:flex-row items-start">
                <span className="font-semibold text-lg">Feedback:</span>
                <p className="ml-2 text-base sm:text-lg">
                  Request feedback from the audience and the Urgetalks team.
                </p>
              </li>
              <li className="flex flex-col sm:flex-row items-start">
                <span className="font-semibold text-lg">Network:</span>
                <p className="ml-2 text-base sm:text-lg">
                  Connect with audience members and other speakers through the
                  platform’s networking features.
                </p>
              </li>
              <li className="flex flex-col sm:flex-row items-start">
                <span className="font-semibold text-lg">Review Recording:</span>
                <p className="ml-2 text-base sm:text-lg">
                  If your talk was recorded, review the recording and share it
                  with your network.
                </p>
              </li>
            </ol>
          </section>

          <section className="mb-6 sm:mb-8">
            <h2 className="text-xl sm:text-2xl font-semibold text-blue-500 mb-4">
              Physical Locations
            </h2>
            <div className="space-y-4 ml-5 sm:ml-6">
              <p className="text-base sm:text-lg">
                You can walk into any of our physical locations to talk after
                booking an appointment.
              </p>
              
              <h3 className="text-lg sm:text-xl font-semibold">Physical Location</h3>
              <p className="text-base sm:text-lg">
                We are thrilled to host speakers at our physical location in the DMV area:
              </p>
              <p className="text-base sm:text-lg">
                Washington, D.C., Maryland, Virginia
              </p>
              <p className="text-base sm:text-lg">
                For any inquiries regarding your speaking proposal or if you
                need assistance with directions, please contact our office at{" "}
                <a href="tel:+1234567890" className="text-blue-600 underline">
                  +1 (234) 567-890
                </a>{" "}
                or email us at{" "}
                <a href="mailto:info@urgetalks.com" className="text-blue-600 underline">
                  info@urgetalks.com
                </a>.
              </p>
            </div>
          </section>
        </div>
      </div>
      <div className="bg-bg flex flex-col sm:flex-row gap-3 sm:gap-5 pt-8 sm:pt-16 justify-center overflow-hidden">
        <img src={b1} alt="Urge Mobile" className="object-none sm:self-end w-full sm:w-auto" />
        <img src={b2} alt="Urge Mobile" className="object-cover w-full sm:w-auto" />
        <img src={b3} alt="Urge Mobile" className="object-none sm:self-end w-full sm:w-auto" />
      </div>
      <Footer />
    </>
  );
};

export default SpeakNow;
