import { Api } from "../api/api";
import {handleError} from "../utils/utils";
import { useQuery } from "react-query";

export const useGetSubscription = () => {
  return useQuery(
    ["subscription"],
    async () => await Api.subscription.getSubscriptions(),
    {
      onError: (err) => {
        handleError(err);
      },
    }
  );
};