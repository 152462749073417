import React from "react";
import Nav from "../../components/nav";
import Footer from "../../components/footer";
import RegisterIcon from "../../assets/icons/register";
import Input from "../../components/input";
import { FreeBtn } from "../../components/button";

const Register = () => {
  return (
    <>
      <div className="bg-bg px-7 md:px-14 pt-9">
        <Nav />
        <div className="flex justify-center">
          <div className="bg-off-black p-7 md:p-9 w-fit rounded-3xl mt-5 md:mt-10">
            <div className="flex flex-col gap-3.5 items-center">
              <div className="flex gap-3 items-center">
                <div>
                  <RegisterIcon />
                </div>
                <div className="w-1 h-11 bg-sky-blue"></div>
                <h3 className="text-white uppercase text-2xl font-extrabold">
                  Register
                </h3>
              </div>
              <p className="text-white font-semibold">
                Fill in your details below to create an account
              </p>
            </div>
            <form
              className="text-white w-auto md:w-[500px] p-4 flex flex-col mt-5 gap-5"
              action=""
            >
              <div className="flex gap-5">
              <Input
                name="first_name"
                type="text"
                label="First Name"
                placeholder="John"
              />
              <Input
                name="last name"
                type="text"
                label="Last Name"
                placeholder="Daniel"
              />
              </div>
              <Input
                name="email"
                type="email"
                label="Email Address"
                placeholder="example@example.com"
              />
              <Input
                name="password"
                type="password"
                label="Password"
              />
              <Input
                name="confirm_password"
                type="password"
                label="Confirm Password"
              />
              <FreeBtn
                classes="text-black"
                type="submit"
              >
                REGISTER
              </FreeBtn>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Register;
