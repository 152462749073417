import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LandingPage from "./pages/landingpage";
import Pricing from "./pages/pricing";
import Download from "./pages/download";
import Register from "./pages/register";
import Login from "./pages/login";
import PrivateRoute from "./utils/privateroute";
import Dashboard from "./pages/dashboard";
import StripeApp from "./pages/stripe";
import Privacy from "./pages/privacy";
import Store from "./pages/store";
import Competition from "./pages/competition";
import Collaboaration from "./pages/collaborattion";
import SpeakNow from "./pages/speaks";
import TeamPage from "./pages/Teams/Index";
import JobPostingView from './pages/Job/JobPostingView';
import MyJobPosted from "./pages/Job/MyJobPosted";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/p" element={<PrivateRoute />}>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="subscribe" element={<StripeApp />} />
          </Route>
          <Route exact path="/" element={<LandingPage />} />
          <Route exact path="/pricing" element={<Pricing />} />
          <Route exact path="/download" element={<Download />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/privacy" element={<Privacy />} />
          <Route exact path="/store" element={<Store />} />
          <Route exact path="/competition" element={<Competition />} />
          <Route exact path="/collab" element={<Collaboaration />} />
          <Route exact path="/speak" element={<SpeakNow />} />
          <Route exact path="/team" element={<TeamPage />} />

          <Route exact path="/job" element={<JobPostingView/>} />
          <Route exact path="/myjob" element={<MyJobPosted/>} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
