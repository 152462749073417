import React from "react";
import Nav from "../../components/nav";
import Footer from "../../components/footer";
import Button from "../../components/button";
import b1 from "../../assets/images/b1.png";
import b2 from "../../assets/images/b2.png";
import b3 from "../../assets/images/b3.png";

const SpeakNow = () => {
  return (
    <>
      <div className="bg-bg px-7 md:px-14 pt-9">
        <Nav />
        <div className="text-white mt-20 flex flex-col items-center gap-8">
          <header className="mb-12 text-center w-100">
            <h1 className="text-4xl font-bold">
              Collaborate with Urgetalks to Host Events
            </h1>
            <p className="mt-4 text-lg">
              At Urgetalks, we believe in the power of collaboration to create
              impactful events that inspire and educate. Learn more about what a
              collaboration with us looks like and how you can benefit from
              partnering with Urgetalks. When you collaborate with Urgetalks to
              host an event, you can expect a seamless partnership designed to
              enhance the overall experience for both speakers and attendees.
              Here’s how our collaboration process works:
            </p>
          </header>

          <section className="mt-8 w-100 text-center">
            <h2 className="text-2xl font-semibold text-blue-500 mb-4">
              Ready to Collaborate?
            </h2>
            <p className="text-lg mb-4">
              If you're interested in hosting an event with Urgetalks, we would
              love to hear from you. Register now to start the collaboration
              process and make your event a success.
            </p>
            <Button
              onClick={() =>
                (window.location.href = "https://docs.google.com/forms/d/e/1FAIpQLSdagqTyMEoy5mUiWcTzDf3xekCGQknw--4RooItTiCeLDm-mQ/viewform")
              }
            >
              Register Now
            </Button>
          </section>

          <section className="mb-8 w-100">
            <h2 className="text-2xl font-semibold text-blue-500 mb-4 text-center">
              Option 1: Joint Event Planning
            </h2>
            <ol className="list-decimal list-inside space-y-2">
              <li className="flex items-start">
                <p className="ml-2 text-lg">
                  We work closely with event hosts in the DMV area to plan and
                  organize the event. This includes selecting the venue,
                  scheduling, and coordinating the logistics to ensure
                  everything runs smoothly.
                </p>
              </li>
            </ol>
          </section>

          <section className="mb-8 w-100">
            <h2 className="text-2xl font-semibold text-blue-500 mb-4 text-center">
              Option 2: Speaker Participation
            </h2>
            <ol className="list-decimal list-inside space-y-4">
              <li className="flex items-start">
                <p className="ml-2 text-lg">
                  A speaker from Urgetalks will have the opportunity to present
                  at the event, either as a keynote speaker or as a session
                  host. This allows us to share our insights and engage with the
                  community while adding value to your event.
                </p>
              </li>
            </ol>
          </section>

          <section className="mb-8 w-100">
            <h2 className="text-2xl font-semibold text-blue-500 mb-4 text-center">
              Option 3: Event Support
            </h2>
            <ol className="list-decimal list-inside space-y-4">
              <li className="flex items-start">
                <p className="ml-2 text-lg">
                  In return, Urgetalks provides support to the event host
                  through video and audio coverage. Our professional team
                  ensures high-quality recordings of the event, which can be
                  used for future promotions and content creation.
                </p>
              </li>
            </ol>
          </section>

          <section className="mb-8 w-100">
            <h2 className="text-2xl font-semibold text-blue-500 mb-4 text-center">
              Option 4: Promotion and Marketing
            </h2>
            <ol className="list-decimal list-inside space-y-4">
              <li className="flex items-start">
                <p className="ml-2 text-lg">
                  Urgetalks leverages its network and marketing channels to
                  promote the event, increasing visibility and attendance. We
                  use social media, email newsletters, and our website to reach
                  a wider audience.
                </p>
              </li>
            </ol>
          </section>

          <section className="mb-8 w-100">
            <h2 className="text-2xl font-semibold text-blue-500 mb-4 text-center">
              Option 5: Post-Talk Follow-Up
            </h2>
            <ol className="list-decimal list-inside space-y-4">
              <li className="flex items-start">
                <p className="ml-2 text-lg">
                  After the event, we collaborate on follow-up activities such
                  as sharing recordings, gathering feedback, and discussing
                  future partnership opportunities. This ensures ongoing
                  engagement and continuous improvement for future events.
                </p>
              </li>
            </ol>
          </section>
        </div>
      </div>
      <div className="bg-bg flex gap-5 pt-16 justify-center overflow-hidden">
        <img src={b1} alt="Urge Mobile" className="object-none self-end" />
        <img src={b2} alt="Urge Mobile" className="object-cover" />
        <img src={b3} alt="Urge Mobile" className="object-none self-end" />
      </div>
      <Footer />
    </>
  );
};

export default SpeakNow;
