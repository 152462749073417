import { useMutation } from "react-query";
import { toast } from "react-hot-toast";
import { useSetRecoilState } from "recoil";
import { Api } from "../api/api";
import userAtom from "../atoms/user.atom";
import { handleError } from "../utils/utils";

export const useLogin = () => {
  const setUserData = useSetRecoilState(userAtom);
  return useMutation((data) => Api.auth.signInEmail(data), {
    onSuccess: ({ data }) => {
      setUserData(data.data);
      toast.success(data.message);
    },
    onError: (err) => {
      toast.error(err);
      // handleToastError(err);
    },
  });
};

export const useRefreshToken = () => {
  const setUserData = useSetRecoilState(userAtom);
  return useMutation((data) => Api.auth.refreshToken(data), {
    onSuccess: ({ data }) => {
      toast.success(data.message);
      setUserData(data.data);
    },
    onError: (err) => {
      handleError(err);
    },
  });
};
