import { useMutation } from "react-query";
import { toast } from "react-hot-toast";
import { Api } from "../api/api";

export const useSubmitEntry = () => {
  return useMutation((data) => Api.competition.submitEntry(data), {
    onSuccess: ({ data }) => {
      toast.success(data.message);
    },
    onError: (err) => {
      toast.error(err);
    },
  });
};
