import React, {useState} from "react";
import Nav from "../../components/nav";
import Footer from "../../components/footer";
import Input from "../../components/input";
import { FreeBtn } from "../../components/button";
import LoginIcon from "../../assets/icons/login";
import { useLogin } from "../../hooks/auth.hook";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginData({ ...loginData, [name]: value });
  };

  const { mutate, isLoading } = useLogin();
  const handleSubmit = (e) => {
    e.preventDefault();
    mutate(loginData, {
      onSuccess: () => {
        navigate("/p/dashboard");
      },
    });
  };

  return (
    <div className="bg-bg lg:h-screen">
    <div className="px-7 md:px-14 pt-9">
      <Nav />
      <div className="flex justify-center">
        <div className="bg-off-black p-7 md:p-9 w-fit rounded-3xl mt-5 md:mt-10">
          <div className="flex flex-col gap-3.5 items-center">
            <div className="flex gap-3 items-center">
              <div className="text-white">
                <LoginIcon />
              </div>
              <div className="w-1 h-11 bg-sky-blue"></div>
              <h3 className="text-white uppercase text-2xl font-extrabold">
                Login
              </h3>
            </div>
            <p className="text-white font-semibold">
              Fill in your details below to Login
            </p>
          </div>
          <form
            className="text-white w-auto md:w-[500px] p-4 flex flex-col mt-5 gap-5"
            onSubmit={handleSubmit}
          >
            <Input
              name="email"
              type="email"
              label="Email Address"
              placeholder="example@example.com"
              onChange={handleChange}
              value={loginData.email}
            />
            <Input
              name="password"
              type="password"
              label="Password"
              onChange={handleChange}
              value={loginData.password}
            />
            
            <FreeBtn
              classes="text-black"
              type="submit"
            >
              {isLoading? "Loading":"LOGIN"}
            </FreeBtn>
          </form>
        </div>
      </div>
      
    </div>
    <Footer />
    </div>
  );
};

export default Login;
