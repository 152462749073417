import React from "react";
import Nav from "../../components/nav";
import Girl from "../../assets/images/girl.png";
import Phone from "../../assets/images/phone.png";
import Phone2 from "../../assets/images/phone2.png";
import b1 from "../../assets/images/b1.png";
import b2 from "../../assets/images/b2.png";
import b3 from "../../assets/images/b3.png";
import Button from "../../components/button";
import Footer from "../../components/footer";
import { useNavigate } from "react-router-dom";
import { useGetLandingPageData } from "../../hooks/anon.hook";
import Loader from "../../components/Loader";
import Marquee from "react-fast-marquee";
import Calender from "../../assets/icons/calender";
import Time from "../../assets/icons/time";
import moment from "moment/moment";
import {timeToAMPM} from "../../utils/utils";

const LandingPage = () => {
  const navigate = useNavigate();
  const { data, isFetching } = useGetLandingPageData();

  return (
    <>
      <div className="hero-pattern h-screen px-7 lg:px-14 pt-9">
        <Nav />
        <div className="text-white flex flex-col justify-center items-center text-center gap-4 mt-[127px] md:mt-[169px]">
          <h1 className="font-[800] text-4xl md:text-5xl mb-4 md:max-w-[945px]">
            Sharing <span className="text-yellow">Great Ideas</span> and
            Perspectives with the World!
          </h1>
          <p className="font-[600] text-lg md:text-xl  md:max-w-[945px]">
            Urge talks is a Network predicated on amplifying voices and
            expressions, usually in the form of short, powerful videos and talks
          </p>
        </div>
        <div className="mt-20 md:flex justify-center">
          <Button onClick={() => navigate("/download")}>START STREAMING</Button>
        </div>
      </div>
      <div className="bg-sky-blue px-7 md:px-14 pt-9 flex justify-around items-center flex-wrap text-center lg:text-left">
        <h3 className="text-white font-[700] w-[551px] text-2xl md:text-[40px] leading-10 md:leading-idanx">
          Listen to the world’s most intriguing speakers share their ideas
          on-the-go using the urge app
        </h3>
        <div className="flex object-contain">
          <img
            src={Girl}
            alt="Girl listening to podcast"
          />
        </div>
      </div>
      <div className="bg-bg pt-9 flex flex-col items-center">
        <h1 className="text-sky-blue font-[700] text-xl md:text-2xl mt-5 md:mt-20">
          Top Masterclasses
        </h1>
        {isFetching ? (
          <Loader />
        ) : (
          <div className="my-7 md:my-14 flex-1 overflow-hidden w-full">
            <Marquee pauseOnHover="true" autoFill="true">
              {data?.data?.data.masterclass.map((item) => (
                <div
                  className="text-white rounded-lg overflow-hidden h-[255px] mr-4"
                  key={item.id}
                  onClick={() => navigate("/download")}
                >
                  <div className="flex w-[280px] h-[165px] overflow-hidden justify-center">
                    <img
                      className="object-cover flex-1"
                      src={item.coverImage}
                      alt={item.title}
                    />
                  </div>
                  <div className="py-1.5 w-[280px]">
                    <h3 className="font-semibold text-base line-clamp-2">
                      {item.title}
                    </h3>
                    <h4 className="mt-0.5">{item.speaker}</h4>
                  </div>
                </div>
              ))}
            </Marquee>
          </div>
        )}
      </div>
      <div className="bg-off-black px-7 md:px-14 pt-9 flex justify-around items-center flex-wrap ">
        <div className="flex flex-col gap-4 text-center lg:text-left">
          <h1 className="font-[800] text-sky-blue text-lg md:text-xl">
            MASTERCLASS
          </h1>
          <p className="font-[700] text-white text-2xl md:text-4xl sm:w-[490px] leading-10 md:leading-idan">
            Watch captivating, insightful, mind changing masterclasses on the
            urge app from top speakers around the world
          </p>
        </div>
        <div className="flex object-contain w-[310px] md:w-auto mt-8 lg:mt-auto">
          <img
            src={Phone}
            alt="Urge Mobile"
          />
        </div>
      </div>
      <div className="bg-bg px-7 md:px-14 pt-9 flex justify-around items-center flex-wrap">
        <div className="order-2 lg:order-1 mt-10 lg:mt-0 lg:flex-1 lg:flex lg:justify-center">
          <img
            src={Phone2}
            alt="Urge Mobile"
          />
        </div>
        <div className="order-1 lg:order-2 text-center lg:text-right lg:flex-1">
          <h2 className="font-[800] text-sky-blue text-lg lg:text-xl">
            EVENTS
          </h2>
          <p className="font-[700] text-white text-2xl lg:text-4xl lg:leading-idan">
            Get exclusive access to transforming events organized by Urge as an
            insider
          </p>
        </div>
      </div>
      <div className="bg-bg pt-9 flex flex-col items-center">
        <h1 className="text-sky-blue font-[700]text-xl md:text-2xl">
          Top Events
        </h1>
        <p className="text-white text-base md:text-xl leading-6 mdleading-9 text-center xl:w-[1000px]">
          Register for, and attend top events from Urge
        </p>
        {isFetching ? (
          <Loader />
        ) : (
          <div className="my-7 md:my-14  flex-1 overflow-hidden w-full">
            <Marquee pauseOnHover="true" autoFill="true">
              {data?.data?.data.events.map((item) => (
                <div
                  className="text-white rounded-lg overflow-hidden h-[255px] mr-4"
                  key={item.id}
                  onClick={() => navigate("/download")}
                >
                  <div className="flex w-[280px] h-[165px] overflow-hidden justify-center">
                    <img
                      className="object-cover flex-1"
                      src={item.cover}
                      alt={item.name}
                    />
                  </div>
                  <div className="py-1.5 w-[280px]">
                    <h3 className="font-semibold text-base line-clamp-2">
                      {item.name}
                    </h3>
                    <div className="flex gap-3">
                      <div className="flex items-center text-xs gap-1">
                        <div><Calender/></div>
                        <h3>{moment(item.date).format("Do MMM, YYYY")}</h3>
                      </div>
                      <div className="flex items-center text-xs gap-1">
                        <div><Time/></div>
                        <h3>{timeToAMPM(item.time)}</h3>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Marquee>
          </div>
        )}
      </div>
      <div className="bg-off-black px-7 md:px-14 pt-9 flex justify-around items-center flex-wrap">
        <div className="flex flex-col gap-4 text-center lg:text-left lg:flex-1">
          <h1 className="font-[800] text-sky-blue text-lg md:text-xl">
            URGE PREMIUM
          </h1>
          <p className="font-[700] text-white text-2xl md:text-4xl sm:w-[510px] leading-10 md:leading-idan">
            Subscribe and gain access to all videos, masterclasses, and events,
            for only $20/month
          </p>
          <div className="mt-6">
            <Button onClick={() => navigate("/p/subscribe")}>SUBSCRIBE</Button>
          </div>
        </div>
        <div className="lg:flex-1 mt-10 lg:mt-0 lg:flex lg:justify-center">
          <img
            src={Phone}
            alt="Urge Mobile"
          />
        </div>
      </div>
      {/* <div className="bg-bg px-7 md:px-14 pt-9 flex flex-col items-center">
        <div className=" flex flex-col items-center">
          <h1 className="text-sky-blue font-[700] text-lg lg:text-2xl">
            Donate To Brinks Foundation
          </h1>
          <p className="text-white mt-3.5 mb-10 lg:mb-14 font-[800] text-2xl lg:text-4xl leading-10 lg:leading-idan text-center md:w-[550px] lg:w-[900px] xl:w-[1000px]">
            Donate to support Brinks Foundation here
          </p>
        </div>
        <div className="mb-10 lg:mb-24">
          <a
            href="https://swiftpay.accessbankplc.com/@byinks"
            target="_blank"
          >
            <Button>DONATE</Button>
          </a>
        </div>
      </div> */}
      <div className="bg-off-black lg:px-14 pt-9 flex flex-col justify-around items-center text-center">
        <div className="px-7 lg:px-0">
          <h1 className="text-white font-[700] text-2xl lg:text-3xl lg:leading-13 xl:w-[1000px] mb-8">
            Download the <span className="text-sky-blue">Urge app</span> now and
            start watching powerful videos that will transform your mind
          </h1>
          <Button onClick={() => navigate("/download")}>Download</Button>
        </div>
        <div className="flex gap-5 mt-16 justify-center overflow-hidden">
          <img
            src={b1}
            alt="Urge Mobile"
            className="object-none self-end"
          />
          <img
            src={b2}
            alt="Urge Mobile"
            className="object-cover"
          />
          <img
            src={b3}
            alt="Urge Mobile"
            className="object-none self-end"
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default LandingPage;
