import React, {useState, forwardRef} from "react";
import EyesClosed from "../../assets/icons/eyesclosed";
import EyesOpen from "../../assets/icons/eyesopen";

const Input = ({
  label,
  name,
  type,
  placeholder,
  autoComplete,
  value,
  onChange,
  t2,
  notRequired
}) => {
  const [eyes, setEyes] = useState(false);
  return (
    <div className="relative">
      <label
        htmlFor={name}
      className={`font-semibold ${t2 ? "text-sm" : "absolute top-[-8.8px] left-4 md:left-8 bg-off-black pl-1 pr-3 text-xs"} outline-none`}
      >
        {label}
      </label>
      {type === "password" ? (
        <>
          <input
            id={name}
            name={name}
            type={eyes ? "text" : "password"}
            autoComplete={autoComplete || "no-autofill"}
            onChange={onChange}
            value={value}
            className="border border-white rounded-3xl px-5 py-4 bg-transparent text-white focus:outline-none focus:bg-transparent autofill:bg-transparent w-full transition duration-200 shadow-escape webkit-text-fill-white"
            required
          />
          <div className="absolute inset-y-0 right-0 cursor-pointer pr-3 flex items-center " onClick={() => setEyes(!eyes)} >
            {eyes ? (
              <EyesOpen />
            ) : (
              <EyesClosed/>
            )}
          </div>
        </>
      ) : (
        <input
          id={name}
          name={name}
          type={type || "text"}
          autoComplete={autoComplete || "no-autofill"}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          className={`border border-white ${t2 ? "":"rounded-3xl"} px-5 py-4 bg-transparent text-white focus:outline-none focus:bg-transparent autofill:bg-transparent w-full transition duration-200 shadow-escape webkit-text-fill-white`}
          required={!notRequired}
        />
      )}
    </div>
  );
};

export default Input;

export const VideoSelector = forwardRef(
  ({ name, label, fileName, ...props }, ref) => {
    return (
      <div className="mb-6 flex justify-between border-1 border-solid border-white rounded-3.5xl px-5 py-4 items-center text-text bg-transparent w-full">
        <label htmlFor={name}>
          {label}
        </label>
        <input
          className="hidden"
          type="file"
          accept="video/*"
          name={name}
          ref={ref}
          {...props}
        />
        {fileName ? (
          <span className="font-semibold" onClick={() => ref.current.click()}>{fileName}</span>
        ) : (
          <span className="px-10 py-3 bg-sky-blue rounded-full text-black font-semibold cursor-pointer text-sm" onClick={() => ref.current.click()}>
            Browse
          </span>
        )}
      </div>
    );
  }
);