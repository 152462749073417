import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import './index.css';
import { QueryClient, QueryClientProvider } from "react-query";
import { RecoilRoot } from "recoil";
import { Toaster } from "react-hot-toast";


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
<RecoilRoot>
    <QueryClientProvider client={queryClient}>
      <Toaster
        position="top-right"
        toastOptions={{
          duration: 5000,
          success: {
            style: {
              background: "#F5F6FD",
            },
          },
          error: {
            style: {
              background: "#FFF3F3",
            },
          },
          style: {
            borderRadius: "8px",
            maxWidth: "366px",
            minHeight: "48px",
            boxShadow: "none",
            fontFamily: "Open Sans, sans-serif",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "17px",
            letterSpacing: "0.01em",
            color: "#000B14",
            textAlign: "left",
            flex: "unset",
          },
        }}
      />
      <App />
    </QueryClientProvider>
  </RecoilRoot>
);
