import React, { useState } from "react";
import Loader from "../../components/Loader";

const Store = () => {
  const [isLoading, setIsLoading] = useState(true);
  const handleLoad = () => {
    setIsLoading(false);
  };
  return (
    <div className="w-screen h-screen flex flex-col bg-bg">
      {isLoading && <Loader />}
      <iframe
        src="https://urge-store.square.site"
        className="w-full h-full"
        title="UrgeStore"
        onLoad={handleLoad}
      ></iframe>
    </div>
  );
};

export default Store;
